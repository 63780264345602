document.addEventListener("DOMContentLoaded", function(event) { 

	// Cat page filter
	var catLinks = document.querySelectorAll('.cat-link');

	for (var i = 0; i < catLinks.length; i++) {

		catLinks[i].onclick = function(event) {

			[].forEach.call(catLinks, function(el) {
			    el.classList.remove('active');
			});
			
			this.classList.add('active');
			
			var allArticles = document.querySelectorAll('.article-holder');
			var currentCat = this.getAttribute('data-cat'); 
			var currentArticles = document.getElementsByClassName(currentCat);

			var i = 0;
			
			if ( currentCat == 'all' ) {

				[].forEach.call(allArticles, function(el) {
				    el.classList.add('active');
				});

			} else {

				[].forEach.call(allArticles, function(el) {
				    el.classList.remove('active');
				});

				[].forEach.call(currentArticles, function(el) {
				    el.classList.add('active');
				});
				
			}

			var allActiveArticles = document.querySelectorAll('.article-holder.active');
			var numberOfActiveArticles = allActiveArticles.length;

			if ( numberOfActiveArticles < 3 ) {

				[].forEach.call(allActiveArticles, function(el) {
				    el.classList.add('long');
				    el.classList.add('col-12');
				    el.classList.remove('low');
				    el.classList.remove('smallsquare');
				});

			} else if ( numberOfActiveArticles > 2 && numberOfActiveArticles < 6 ) {

				[].forEach.call(allActiveArticles, function(el) {
				    el.classList.add('long');
				    el.classList.add('low');
				    el.classList.add('col-12');
				    el.classList.remove('smallsquare');
				});

			} else {

				[].forEach.call(allActiveArticles, function(el) {
				    el.classList.add('smallsquare');
				    el.classList.remove('low');
					el.classList.remove('long');
					el.classList.remove('col-12');
				});
			}
		}
	}
	// END Cat page filter


	// Mobile menu
	var mobileMenuIcon = document.getElementById('js-toggle-mobile-menu');
	var mobileMenu = document.getElementById('mobile-menu');
	var active = false;

	mobileMenuIcon.onclick = function(event) {

		if ( active == false ) {

			active = true;
			console.log('show');

			this.classList.add('open');
			mobileMenu.classList.add('open');

		} else {

			active = false;
			console.log('hide');

			this.classList.remove('open');
			mobileMenu.classList.remove('open');

		}
	}
	// END Mobile menu


	// Order magazine
	var orderMag = document.getElementById('js-submit');
	var validateFields = document.querySelectorAll('.js-validate');
	var checkboxVal = document.getElementById('js-validate-checkbox');
	var checkbox = document.getElementById('checkboxAgreeLabel');

	for (var i = 0; i < validateFields.length; i++) {

		validateFields[i].onclick = function(event) {

			this.classList.remove('has-error');
		}
	}

	checkboxVal.onclick = function(event) {

		checkbox.classList.remove('has-error');
	
	}

	orderMag.onclick = function(event) {

		event.preventDefault();

		var form = document.getElementById('order-form');
		var error = [];

		// Validate Fields.
		[].forEach.call(validateFields, function(el) {
		    el.classList.remove('has-error');
		});

		[].forEach.call(validateFields, function(el) {
			if ( el.value == '' ) {
				el.classList.add('has-error');
				error.push(true);
			}
		});

		// Validate checkbox
		checkbox.classList.remove('has-error');
		
		if ( !checkboxVal.checked ) {
			checkbox.classList.add('has-error');
			error.push(true);
		}

		// Check if we got validation errors
		if ( error.length > 0 ) {
			return;
		}

		// Prevent double posts
		this.classList.add('disable');

		// Let's store the data
		var name = document.getElementById('order-name').value;
		var address = document.getElementById('order-address').value;
		var zip = document.getElementById('order-zip').value;

		var data = {
			name : name,
			address : address,
			zip : zip,
			action : 'contact_send'
		}

		var params = encodeParams(data);

		const url = AJAX_URL;
		const http = new XMLHttpRequest();

		http.open("POST", url, true);
		http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

		http.onreadystatechange = () => {
			if ( http.readyState == 4 && http.status == 200 ) {
				let response = http.responseText;
				try {
					let data = JSON.parse(response);

					var ajaxMessage = document.getElementById('ajax-message');

					form.classList.add('hide');
					ajaxMessage.classList.add('show');
					

				}catch(e){
					console.error(e);
				}
			}
		};
		http.send(params);

	}

	function encodeParams(data) {
	   let str = [];
	   for ( const p in data ) {
	       if ( data.hasOwnProperty(p) ) {
	           str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
	       }
	   }
	   return str.join("&");
	}
	// END Order magazine

});